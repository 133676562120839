<template>
  <CruiseModal :showModal="showModal" @update:showModal="updateShowModal" title="항차 선택" class="cancel-modal">
    <div class="voyage-list-wrapper">
      <div class="voyage-table">
        <CruiseTable :theadList="theadList" :tbodyList="voyageList" @onAllCheck="handleAllCheck">
          <template #checkbox="{ item }">
            <CruiseCheckbox v-model="item.isSelected" />
          </template>
          <template #voyageNumber="{ item }">
            {{ item.leaveVoyageNumber }}
          </template>
          <template #leaveVoyageDate="{ item }">
            {{ item.leaveDate }}
          </template>
          <template #leaveVoyageDepartureTime="{ item }">
            {{ item.voyagePair.leave.departureExpectTm }}
          </template>
          <template #leaveVoyageArrivalTime="{ item }">
            {{ item.voyagePair.leave.arriveExpectTm }}
          </template>

          <template v-if="isRound" #returnVoyageNumber="{ item }">
            {{ item.returnVoyageNumber }}
          </template>
          <template v-if="isRound" #returnVoyageDate="{ item }">
            {{ item.returnDate }}
          </template>
          <template v-if="isRound" #returnVoyageDepartureTime="{ item }">
            {{ item.voyagePair.return?.departureExpectTm }}
          </template>
          <template v-if="isRound" #returnVoyageArrivalTime="{ item }">
            {{ item.voyagePair.return?.arriveExpectTm }}
          </template>
          <template #vesselCode="{ item }">
            {{ item.voyagePair.leave.vesselCd }}
          </template>
        </CruiseTable>
      </div>
      <CruisePagination :page="activePage" :pages="totalPage" @update:page="handlePage" />
    </div>

    <div class="btn-wrapper">
      <CruiseButton class="bottom-btn" @click="saveVoyageList">저장</CruiseButton>
      <CruiseButton class="bottom-btn" theme="s" @click="closeModal">취소</CruiseButton>
    </div>
  </CruiseModal>
</template>

<script>
import { mapMutations } from 'vuex';
import { YYYYMMDD } from '@/const/const';
import { formatDate } from '@/utils/convert';

import TourService from '@/services/tour';

import CruiseModal from '@/components/modal/CruiseModal.vue';
import CruiseButton from '@/components/button/CruiseButton';
import CruiseTable from '@/components/table/CruiseTable.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruisePagination from '@/components/common/CruisePagination.vue';

export default {
  name: 'VoyageListModal',
  emits: ['update:showModal', 'saveVoyageList'],
  components: { CruiseModal, CruiseButton, CruiseTable, CruiseCheckbox, CruisePagination },
  props: {
    showModal: Boolean,
    tourProductInfo: Object,
    voyageDate: Array,
    backUpSelectedList: Array,
    isRound: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      theadRound: [
        { key: 'checkbox' },
        { label: '가는 편 항차', key: 'voyageNumber', css: { width: '70px' } },
        { label: '가는 편 일자', key: 'leaveVoyageDate', css: { width: '70px' } },
        { label: '가는 편 출항시간', key: 'leaveVoyageDepartureTime', css: { width: '70px' } },
        { label: '가는 편 도착시간', key: 'leaveVoyageArrivalTime', css: { width: '70px' } },
        { label: '오는 편 항차', key: 'returnVoyageNumber', css: { width: '70px' } },
        { label: '오는 편 일자', key: 'returnVoyageDate', css: { width: '70px' } },
        { label: '오는 편 출항시간', key: 'returnVoyageDepartureTime', css: { width: '70px' } },
        { label: '오는 편 도착시간', key: 'returnVoyageArrivalTime', css: { width: '70px' } },
        { label: '선박', key: 'vesselCode', css: { width: '70px' } },
      ],
      theadOneWay: [
        { key: 'checkbox' },
        { label: '가는 편 항차', key: 'voyageNumber', css: { width: '70px' } },
        { label: '가는 편 일자', key: 'leaveVoyageDate', css: { width: '70px' } },
        { label: '가는 편 출항시간', key: 'leaveVoyageDepartureTime', css: { width: '70px' } },
        { label: '가는 편 도착시간', key: 'leaveVoyageArrivalTime', css: { width: '70px' } },
        { label: '선박', key: 'vesselCode', css: { width: '70px' } },
      ],

      voyageList: [],
      voyageTotalList: [],
      selectedVoyageList: [],
      totalPage: 0,
      activePage: 1,
    };
  },
  computed: {
    theadList() {
      return this.isRound ? this.theadRound : this.theadOneWay;
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),

    async init() {
      if (this.tourProductInfo.packageTourSchedule && this.tourProductInfo.packageTourSchedule.length > 0) {
        this.selectedVoyageList = this.tourProductInfo.packageTourSchedule;
      }
      await this.getVoyageList();
    },
    async getVoyageList(page) {
      try {
        this.setIsLoading(true);

        // 페이지네이션 이동 시 선택된 항차 리스트를 유지하기 위해
        if (this.voyageList) {
          const beforeSelectedVoyageList = this.voyageList.filter(voyage => voyage.isSelected);

          this.selectedVoyageList = this.selectedVoyageList.concat(
            beforeSelectedVoyageList.filter(voyage => {
              return !this.selectedVoyageList.some(
                existingVoyage =>
                  existingVoyage.leaveVoyageNumber === voyage.leaveVoyageNumber &&
                  (!this.isRound || existingVoyage.returnVoyageNumber === voyage.returnVoyageNumber),
              );
            }),
          );

          this.voyageList.forEach(voyage => {
            if (voyage.isSelected) {
              const existingIndex = this.selectedVoyageList.findIndex(
                selectedVoyage =>
                  selectedVoyage.leaveVoyageNumber === voyage.leaveVoyageNumber &&
                  (!this.isRound || selectedVoyage.returnVoyageNumber === voyage.returnVoyageNumber),
              );
              if (existingIndex === -1) {
                this.selectedVoyageList.push(voyage);
              }
            } else {
              const idx = this.selectedVoyageList.findIndex(
                selectedVoyage =>
                  selectedVoyage.leaveVoyageNumber === voyage.leaveVoyageNumber &&
                  (!this.isRound || selectedVoyage.returnVoyageNumber === voyage.returnVoyageNumber),
              );
              if (idx !== -1) {
                this.selectedVoyageList.splice(idx, 1);
              }
            }
          });
        }

        if (this.backUpSelectedList.length > 0) {
          this.selectedVoyageList = this.backUpSelectedList;
        }

        const params = {
          vesselCode: this.tourProductInfo.vessel.code,
          routeCode: this.tourProductInfo.route.code,
          departurePortCode: this.tourProductInfo.departurePort.code,
          arrivalPortCode: this.tourProductInfo.arrivalPort.code,
          startDate: formatDate(this.voyageDate[0], YYYYMMDD),
          endDate: formatDate(this.voyageDate[1], YYYYMMDD),
          term: this.isRound ? this.tourProductInfo.term : null,
          page: page || 1,
          limit: 10,
        };
        const { data } = await TourService.getVoyageList(params);
        const newVoyages = data.voyagePairs;
        this.voyageList = newVoyages;
        this.totalPage = data.pages;

        const mergedVoyages = [...(this.voyageTotalList || []), ...newVoyages];
        this.voyageTotalList = mergedVoyages.filter(
          (voyage, index, self) =>
            index ===
            self.findIndex(
              v =>
                v.leaveVoyageNumber === voyage.leaveVoyageNumber &&
                (!this.isRound || v.returnVoyageNumber === voyage.returnVoyageNumber),
            ),
        );

        const beforeSelectedVoyageList = this.voyageList.filter(voyage => voyage.isSelected);

        this.selectedVoyageList = this.selectedVoyageList.concat(
          beforeSelectedVoyageList.filter(voyage => {
            return !this.selectedVoyageList.some(
              existingVoyage =>
                existingVoyage.leaveVoyageNumber === voyage.leaveVoyageNumber &&
                (!this.isRound || existingVoyage.returnVoyageNumber === voyage.returnVoyageNumber),
            );
          }),
        );

        // 선택된 항차 리스트가 있을 경우
        if (this.selectedVoyageList.length > 0) {
          this.setSelectedVoyageList(this.selectedVoyageList);
        }
        if (this.backUpSelectedList.length > 0) {
          // 백업된 선택된 항차 리스트가 있을 경우
          this.setSelectedVoyageList(this.backUpSelectedList);
        }

        if (this.voyageList === null) {
          this.closeModal();
          alert('해당하는 항차가 없습니다.\n조회 조건을 다시 선택해주세요.');
        }
      } catch (error) {
        this.closeModal();
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    setSelectedVoyageList(selectedList) {
      this.voyageList.forEach(voyage => {
        const selectedVoyage = selectedList.find(selectedItem =>
          this.isRound
            ? selectedItem.leaveVoyageNumber === voyage.leaveVoyageNumber &&
              selectedItem.returnVoyageNumber === voyage.returnVoyageNumber
            : selectedItem.leaveVoyageNumber === voyage.leaveVoyageNumber,
        );

        if (selectedVoyage) {
          voyage.isSelected = true;
        } else {
          voyage.isSelected = false;
        }
      });
    },
    // mergeSelectedVoyageList() {
    //   const beforeSelectedVoyageList = this.voyageList.filter(voyage => voyage.isSelected);

    //   // 중복체크
    //   this.selectedVoyageList = this.selectedVoyageList.concat(
    //     beforeSelectedVoyageList.filter(voyage => {
    //       return !this.selectedVoyageList.some(
    //         existingVoyage => existingVoyage.leaveVoyageNumber === voyage.leaveVoyageNumber,
    //       );
    //     }),
    //   );
    // },
    handleAllCheck(checked) {
      this.voyageList?.forEach(voyage => {
        voyage.isSelected = checked;
      });
    },
    handlePage(page) {
      this.activePage = page;
      this.getVoyageList(page);
    },
    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    closeModal() {
      this.updateShowModal(false);
    },
    saveVoyageList() {
      this.voyageList.forEach(voyage => {
        if (voyage.isSelected) {
          const existingIndex = this.selectedVoyageList.findIndex(
            selectedVoyage =>
              selectedVoyage.leaveVoyageNumber === voyage.leaveVoyageNumber &&
              (!this.isRound || selectedVoyage.returnVoyageNumber === voyage.returnVoyageNumber),
          );
          if (existingIndex === -1) {
            this.selectedVoyageList.push(voyage);
          }
        } else {
          const idx = this.selectedVoyageList.findIndex(
            selectedVoyage =>
              selectedVoyage.leaveVoyageNumber === voyage.leaveVoyageNumber &&
              (!this.isRound || selectedVoyage.returnVoyageNumber === voyage.returnVoyageNumber),
          );
          if (idx !== -1) {
            this.selectedVoyageList.splice(idx, 1);
          }
        }
      });

      const filteredPackageTourSchedule = this.selectedVoyageList.filter(packageItem =>
        this.voyageTotalList.some(
          scheduleItem =>
            scheduleItem.leaveVoyageNumber === packageItem.leaveVoyageNumber &&
            (!this.isRound || scheduleItem.returnVoyageNumber === packageItem.returnVoyageNumber),
        ),
      );

      this.selectedVoyageList = filteredPackageTourSchedule;

      this.$emit('saveVoyageList', this.selectedVoyageList);
      this.closeModal();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.voyage-list-wrapper {
  width: 100%;
  height: 100%;
  background: var(--White);
  position: relative;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
}

.voyage-table {
  min-height: 460px;
  overflow: auto;
  overflow: auto;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
