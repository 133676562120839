import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index';

import MainContainer from '@/layouts/MainContainer.vue';
import HomeView from '@/views/HomeView.vue';
import LoginForm from '@/views/LoginForm.vue';
import NotFound from '@/views/NotFound.vue';

import CouponRegister from '@/components/coupon/CouponRegister.vue';
import CouponManagement from '@/components/coupon/CouponManagement.vue';

import TourManagement from '@/components/tour/TourManagement.vue';
import TourRegister from '@/components/tour/TourRegister.vue';

import NoticeManagement from '@/components/notice/NoticeManagement.vue';
import NoticeRegister from '@/components/notice/NoticeRegister.vue';

import EventManagement from '@/components/event/EventManagement.vue';
import EventRegister from '@/components/event/EventRegister.vue';

import QnaManagement from '@/components/qna/QnaManagement.vue';
import QnaRegister from '@/components/qna/QnaRegister.vue';

import PopUpManagement from '@/components/popup/PopUpManagement.vue';
import PopUpRegister from '@/components/popup/PopUpRegister.vue';

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: MainContainer,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'home',
        component: HomeView,
      },
      {
        path: 'coupon',
        children: [
          {
            path: '',
            component: CouponManagement,
          },
          {
            path: 'create',
            component: CouponRegister,
          },
          {
            path: 'update',
            component: CouponRegister,
          },
        ],
      },
      {
        path: 'tour',
        children: [
          {
            path: '',
            component: TourManagement,
          },
          {
            path: 'create',
            component: TourRegister,
          },
          {
            path: 'update',
            component: TourRegister,
          },
        ],
      },
      {
        path: 'notice',
        children: [
          {
            path: '',
            component: NoticeManagement,
          },
          {
            path: 'create',
            component: NoticeRegister,
          },
          {
            path: 'update',
            component: NoticeRegister,
          },
        ],
      },
      {
        path: 'event',
        children: [
          {
            path: '',
            component: EventManagement,
          },
          {
            path: 'create',
            component: EventRegister,
          },
          {
            path: 'update',
            component: EventRegister,
          },
        ],
      },
      {
        path: 'qna',
        redirect: '/qna/ko',
      },
      {
        path: 'qna/:lang?',
        redirect: to => {
          const lang = to.params.lang || 'ko';
          return `/qna/${lang}`;
        },
        children: [
          {
            path: '',
            name: 'QnaManagement',
            component: QnaManagement,
          },
          {
            path: 'answer',
            name: 'QnaRegister',
            component: QnaRegister,
          },
        ],
      },
      {
        path: 'pop-up',
        children: [
          {
            path: '',
            component: PopUpManagement,
          },
          {
            path: 'create',
            component: PopUpRegister,
          },
          {
            path: 'update',
            component: PopUpRegister,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    component: LoginForm,
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  await store.dispatch('auth/isLoggedIn');
  const isAuthenticated = store.state.auth.status.loggedIn;

  if (requiresAuth && !isAuthenticated) {
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
