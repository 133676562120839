<template>
  <div class="qna-register-wrapper flex-column">
    <h1 class="page-title">{{ $t('qna.register') }}</h1>

    <div class="qna-contents-wrapper">
      <CruiseInputField :label="$t('profile.name')">
        <div class="field-wrapper">
          {{ qna.name }}
        </div>
      </CruiseInputField>

      <CruiseInputField :label="$t('profile.contact')">
        <div class="field-wrapper">
          {{ qna.mobile }}
        </div>
      </CruiseInputField>

      <CruiseInputField :label="$t('profile.email')">
        <div class="field-wrapper">
          {{ qna.email }}
        </div>
      </CruiseInputField>

      <div class="flex-wrapper group">
        <CruiseInputField :label="$t('qna.category')">
          <div class="field-wrapper">
            {{ getLocalText(qna, 'category') }}
          </div>
        </CruiseInputField>
        <CruiseInputField v-if="qna.route?.code" class="border" :label="$t('qna.route')">
          <div class="field-wrapper">
            {{ getLocalText(qna.route, 'name') }}
          </div>
        </CruiseInputField>
      </div>

      <CruiseInputField :label="$t('qna.content')">
        <div class="textarea-field-wrapper">
          <CruiseTextArea class="input-width" v-model="qna.content" readonly />
          <div class="textarea-length">({{ qna.content?.length }} / 1,000)</div>
        </div>
      </CruiseInputField>

      <CruiseInputField :label="$t('qna.answeredContent')" required>
        <div class="textarea-field-wrapper">
          <CruiseTextArea
            class="input-width"
            :placeholder="$t('message.textLimit', { limit: '1,000' })"
            v-model="answerContent"
          >
          </CruiseTextArea>
          <div class="textarea-length">({{ convertCommaNumber(answerContent.length) }} / 1,000)</div>
        </div>
      </CruiseInputField>
    </div>

    <div class="btn-wrapper">
      <CruiseButton class="select-width" @click="sendAnswer">{{ $t('actions.send') }}</CruiseButton>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { getLocalText, convertCommaNumber } from '@/utils/convert';

import QnaService from '@/services/qna';

import CruiseInputField from '@/components/common/CruiseInputField.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseTextArea from '@/components/common/CruiseTextArea.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';

export default {
  name: 'QnaRegisterJa',
  components: {
    CruiseInputField,
    CruiseInput,
    CruiseTextArea,
    CruiseButton,
  },
  data() {
    return {
      qna: {},
      answerContent: '',
    };
  },
  computed: {
    ...mapState('auth', ['user']),

    qnaId() {
      return this.$route.query.id;
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalText,
    convertCommaNumber,

    init() {
      this.getQnaDetail();
    },
    async getQnaDetail() {
      try {
        this.setIsLoading(true);

        const { result } = await QnaService.getQnaDetail(this.qnaId);
        this.qna = result;
        if (this.qna.answer && this.qna.answer.content) {
          this.answerContent = this.qna.answer.content;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async sendAnswer() {
      try {
        this.setIsLoading(true);

        const params = {
          qna: {
            id: Number(this.qnaId),
          },

          author: this.user.userId,
          content: this.answerContent,
        };

        await QnaService.addAnswer(params);

        alert(this.$t('qna.response.success'));
        this.$router.push('/qna');
      } catch (error) {
        alert(this.$t('qna.response.error'));
      } finally {
        this.setIsLoading(false);
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.qna-contents-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border: 0.5px solid #f0f0f0;
}

.field-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}

.textarea-field-wrapper {
  width: 100%;
  padding: 10px 20px;
}

.textarea-wrapper {
  height: 320px !important;
  margin-bottom: 10px;
}

.textarea-length {
  color: var(--Grey-grey-700);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: right;
}

.flex-wrapper {
  display: flex;
}

.flex-wrapper.group .input-field-wrapper:first-child {
  border-top: 0.5px solid #f0f0f0;
}

.border {
  border-left: 0.5px solid #f0f0f0;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
