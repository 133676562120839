<template>
  <div class="qna-mgmt-wrapper">
    <h1 class="page-title">{{ $t(`qna.title.${routeLang}`) }}</h1>

    <div class="qna-list-wrapper">
      <div class="search-filter">
        <CruiseSelect class="select-width" :options="searchList" v-model="searchType" property="key">
          <template #option="{ item }">
            {{ item.label }}
          </template>
        </CruiseSelect>

        <CruiseInput
          class="input-width"
          :placeholder="$t('labels.searchInput')"
          v-model="searchValue"
          @keyup.enter="searchQnaList"
        />

        <CruiseButton class="reset-btn" theme="s" @click="refresh">{{ $t('actions.reset') }}</CruiseButton>
        <CruiseButton class="search-btn" @click="searchQnaList">{{ $t('actions.search') }}</CruiseButton>

        <CruiseButton class="delete-btn" theme="q" @click="removeQnaList">{{ $t('actions.delete') }}</CruiseButton>
      </div>

      <CruiseTabs v-model:activeTab="activeTab">
        <CruiseTab
          v-for="(tab, i) in tabLabel"
          :title="`${tab.label} ${qnaList.length > 0 ? qnaList[i][tab.key] : 0}`"
          :key="tab.key"
        >
        </CruiseTab>
      </CruiseTabs>

      <div class="qna-table">
        <template v-if="qna.qnas && qna.qnas.length > 0">
          <CruiseTable :theadList="thead" :tbodyList="qna.qnas" @onAllCheck="handleAllCheck" @clickRow="goToUpdatePage">
            <template #checkbox="{ item }">
              <CruiseCheckbox v-model="item.isSelected" />
            </template>
            <template #category="{ item }">
              <div class="text-ellipsis" :title="item.category">
                {{ item.category.label }}
              </div>
            </template>
            <template #status="{ item }">
              <span
                v-if="item.status.code === 'Waiting'"
                class="status waiting text-ellipsis"
                :title="item.status.label"
              >
                {{ item.status.label }}
              </span>
              <span v-else class="status answered text-ellipsis" :title="item.status.label">
                {{ item.status.label }}
              </span>
            </template>
            <template #answeredAt="{ item }">
              <div class="text-ellipsis" :title="item.answer?.createdAt">
                {{ item.answer?.createdAt }}
              </div>
            </template>
            <template #answerer="{ item }">
              <div class="text-ellipsis" :title="item.answer?.author">
                {{ item.answer?.author }}
              </div>
            </template>
            <template #remark="{ item }">
              <CruiseButton class="update-btn" theme="q" @click="goToUpdatePage(item)">
                {{ $t('qna.answer') }}
              </CruiseButton>
            </template>
          </CruiseTable>
        </template>
        <template v-else>
          <div class="empty-message">{{ $t('qna.noData') }}</div>
        </template>
      </div>
      <CruisePagination :page="tabList[activeTab].page" :pages="qna.pages" @update:page="handlePage" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { getLocalText, formatDateRange } from '@/utils/convert';

import QnaService from '@/services/qna';

import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseTabs from '@/components/tab/CruiseTabs.vue';
import CruiseTab from '@/components/tab/CruiseTab.vue';
import CruiseTable from '@/components/table/CruiseTable.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruisePagination from '@/components/common/CruisePagination.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';

export default {
  name: 'QnaManagementJa',
  components: {
    CruiseButton,
    CruiseTabs,
    CruiseTab,
    CruiseTable,
    CruiseCheckbox,
    CruisePagination,
    CruiseSelect,
    CruiseInput,
  },
  data() {
    return {
      activeTab: 0,

      qnaList: [],
      tabList: [
        { statusKo: '', statusJa: '', statusEn: null, page: 1 },
        { statusKo: '대기 중', statusJa: '回答待ち', statusEn: 'Waiting', page: 1 },
        { statusKo: '답변 완료', statusJa: '回答完了', statusEn: 'Solved', page: 1 },
      ],

      isSearch: false,
      searchValue: '',
      searchType: 'category',
      searchList: [
        { key: 'category', label: this.$t('qna.category'), value: '' },
        { key: 'content', label: this.$t('labels.content'), value: '' },
        { key: 'userName', label: this.$t('labels.userName'), value: '' },
      ],
    };
  },
  watch: {
    activeTab() {
      this.tabList.map(item => {
        item.page = 1;
      });
    },
    '$route.params.lang': {
      immediate: true,
      handler(newLang, oldLang) {
        if (newLang !== oldLang) {
          this.init();
        }
      },
    },
  },
  computed: {
    tabLabel() {
      return [
        { key: 'count', label: this.$t('labels.all') },
        { key: 'countProceed', label: this.$t('qna.pending') },
        { key: 'countEnd', label: this.$t('qna.completed') },
      ];
    },
    thead() {
      return [
        { key: 'checkbox' },
        { label: this.$t('qna.category'), key: 'category', css: { width: '120px' } },
        { label: this.$t('labels.content'), key: 'content', css: { width: '300px' } },
        { label: this.$t('labels.userName'), key: 'name', css: { width: '120px' } },
        { label: this.$t('qna.status'), key: 'status', css: { width: '120px' } },
        { label: this.$t('labels.createdAt'), key: 'createdAt', css: { width: '130px' } },
        { label: this.$t('labels.answeredAt'), key: 'answeredAt', css: { width: '130px' } },
        { label: this.$t('labels.answerer'), key: 'answerer', css: { width: '120px' } },
        { label: '', key: 'remark', css: { width: '60px' } },
      ];
    },
    qna() {
      return this.qnaList[this.activeTab] || {};
    },
    routeLang() {
      return this.$route.params.lang || 'ko';
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalText,
    formatDateRange,

    init() {
      this.getTotalQnaList();
    },
    async getQnaList() {
      try {
        this.setIsLoading(true);

        const item = this.tabList[this.activeTab];
        const params = {
          category: this.searchType === 'category' ? this.searchValue : null,
          content: this.searchType === 'content' ? this.searchValue : null,
          name: this.searchType === 'userName' ? this.searchValue : null,
          status: item.statusEn,
          locale: this.routeLang,
          page: item.page || 1,
          limit: 10,
        };

        const { result } = await QnaService.getQnaList(params);
        this.qnaList[this.activeTab] = result;
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async getTotalQnaList() {
      try {
        this.setIsLoading(true);

        const promises = this.tabList.map(item => {
          const params = {
            category: this.searchType === 'category' ? this.searchValue : null,
            content: this.searchType === 'content' ? this.searchValue : null,
            name: this.searchType === 'userName' ? this.searchValue : null,
            status: item.statusEn,
            locale: this.routeLang,
            page: item.page || 1,
            limit: 10,
          };

          return QnaService.getQnaList(params);
        });
        const results = await Promise.all(promises);
        this.qnaList = results.map(res => res.result);
      } catch (error) {
        alert(error);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async removeQnaList() {
      try {
        this.setIsLoading(true);

        const checkedList = this.qnaList[this.activeTab].qnas.filter(qna => qna.isSelected).map(qna => qna.id);

        if (!checkedList.length) {
          alert(this.$t('alert.delete.select'));
          return;
        }

        const params = {
          ids: checkedList.join(','),
        };
        await QnaService.removeQnaList(params);

        alert(this.$t('alert.delete.success'));
        await this.getQnaList();
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    goToUpdatePage(qna) {
      this.$router.push({ path: `/qna/${this.routeLang}/answer`, query: { id: qna.id } });
    },
    handlePage(page) {
      this.tabList[this.activeTab].page = page;
      this.getQnaList();
    },
    handleAllCheck(checked) {
      this.qnaList[this.activeTab].qnas.forEach(qna => {
        qna.isSelected = checked;
      });
    },
    refresh() {
      if (this.isSearch) {
        window.location.reload();
        this.isSearch = false;
      }
    },
    async searchQnaList() {
      this.isSearch = true;
      this.tabList.map(item => {
        item.page = 1;
      });

      await this.getTotalQnaList();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.qna-mgmt-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.qna-list-wrapper {
  position: relative;
  border-radius: 5px;
  min-height: 560px;
  background: var(--White);
}

.status {
  font-weight: 700;
}

.status.waiting {
  color: var(--Red);
}

.status.answered {
  color: var(--Main);
}

.delete-btn {
  margin-left: 65px;
}

.header-title-text > span {
  color: #969696;
  font-size: 16px;
  font-weight: 400;
  margin-left: 70px;
}

.qna-table {
  min-height: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.update-btn {
  width: 40px;
  height: 27px;
}

.search-filter {
  display: flex;
  gap: 10px;
  position: absolute;
  z-index: var(--zContentsMain);
  top: 10px;
  right: 20px;
}

.select-width {
  width: 120px;
}

.input-width {
  width: 250px;
}
</style>
