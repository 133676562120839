<template>
  <div class="side-navigation-wrapper">
    <ul class="menu-item-wrapper">
      <li v-for="item in menu" :key="item.name">
        <div class="item-name-wrapper" @click="toogleMenu(item)">
          <span> {{ item.name }} </span>
          <ChevronDownIcon class="arrow-icon" :class="{ active: item.isOpen }" :size="20" />
        </div>

        <ul class="child-item-wrapper" v-if="item.isOpen">
          <li class="child-item" v-for="child in item.children" :key="child.name">
            <RouterLink class="child-path" :to="child.path">
              {{ child.name }}
            </RouterLink>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown';

export default {
  name: 'SideNav',
  components: { ChevronDownIcon },
  data() {
    return {
      menu: [
        {
          name: '쿠폰',
          isOpen: false,
          children: [
            {
              name: '쿠폰 관리',
              path: '/coupon',
            },
          ],
        },
        {
          name: '투어 상품',
          isOpen: false,
          children: [
            {
              name: '투어 상품 관리',
              path: '/tour',
            },
          ],
        },
        {
          name: '공지사항',
          isOpen: false,
          children: [
            {
              name: '공지사항 관리',
              path: '/notice',
            },
          ],
        },
        {
          name: '이벤트',
          isOpen: false,
          children: [
            {
              name: '이벤트 관리',
              path: '/event',
            },
          ],
        },
        {
          name: this.$t('menu.qna'),
          isOpen: false,
          children: [
            {
              name: this.$t('qna.title.ko'),
              path: '/qna/ko',
            },
            {
              name: this.$t('qna.title.ja'),
              path: '/qna/ja',
            },
            {
              name: this.$t('qna.title.en'),
              path: '/qna/en',
            },
          ],
        },
        {
          name: '팝업',
          isOpen: false,
          children: [
            {
              name: '팝업 관리',
              path: '/pop-up',
            },
          ],
        },
      ],
    };
  },
  methods: {
    toogleMenu(item) {
      item.isOpen = !item.isOpen;
    },
  },
};
</script>

<style scoped>
.side-navigation-wrapper {
  flex-shrink: 0;
  width: 220px;
  min-height: 100%;
  background: #fff;
  border-right: var(--default--border);
}

.menu-item-wrapper {
  transition: all 0.35s ease-in-out;
}

.item-name-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px;
  color: #575757;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.item-name-wrapper:hover {
  color: var(--Blue-blue-500);
}

.arrow-icon {
  transition: all 0.2s ease-out;
}

.arrow-icon.active {
  transform: rotate(180deg);
}

.child-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 30px;
  background: #f0f0f0;
}

.child-item {
  width: 100%;
  height: 100%;
}

.child-path {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 30px;
  color: #575757;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.child-path:hover {
  color: var(--Blue-blue-500);
}
</style>
