<template>
  <div class="login-wrapper">
    <div class="login-inner">
      <div class="login-logo">
        <img class="logo-img" src="@/assets/logo/panstar_full_logo.svg" alt="panstar cruise logo" />
      </div>

      <form class="login-form-wrapper" @submit.prevent="handleLogin">
        <div class="login-form">
          <CruiseInput class="input-id" name="id" v-model="userId" placeholder="아이디 입력" required />
          <CruiseInput
            class="input-pw"
            type="password"
            name="pw"
            v-model="userPassword"
            placeholder="비밀번호 입력"
            required
          />
        </div>
        <div class="save-id">
          <CruiseCheckbox v-model="isSaveId" label="아이디 저장" />
        </div>
        <CruiseButton type="submit" class="login-btn">로그인</CruiseButton>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getItem, setItem, removeItem } from '@/utils/localStorage';

import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';

export default {
  name: 'LoginForm',
  components: { CruiseInput, CruiseCheckbox, CruiseButton },
  data() {
    return {
      userId: getItem('userId') || '',
      userPassword: '',
      isSaveId: false,
      isLogin: false,
    };
  },
  watch: {
    isSaveId(newValue) {
      if (newValue) {
        setItem('userId', this.userId);
      } else {
        removeItem('userId');
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  created() {
    this.init();
  },
  mounted() {
    this.loadUserId();
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),

    init() {
      if (this.user) this.$router.push('/home');
    },
    loadUserId() {
      const userIdInStorage = getItem('userId');
      if (userIdInStorage) {
        this.userId = userIdInStorage;
        this.isSaveId = true;
      }
    },
    async handleLogin() {
      if (this.isLogin) return;
      this.isLogin = true;

      try {
        if (!this.userId || !this.userPassword) {
          return alert('아이디와 비밀번호를 모두 입력해주세요.');
        }

        this.setIsLoading(true);
        const params = { userId: this.userId, password: this.userPassword };
        await this.$store.dispatch('auth/login', params);

        if (this.isSaveId) {
          setItem('userId', this.userId);
        } else {
          removeItem('userId');
        }

        const redirect = this.$route.query.redirect || '/home';
        this.$router.push(redirect);
      } catch (error) {
        alert(error);
      } finally {
        this.isLogin = false;
        this.setIsLoading(false);
      }
    },
  },
};
</script>

<style scoped>
.login-wrapper {
  flex: 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-inner {
  font-size: 14px;
  width: 396px;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
  background: var(--White);
  padding: 0 20px 40px;
}

.login-logo {
  width: 100%;
  height: 24px;
  margin: 40px 0;
}

.logo-img {
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.input-id,
.input-pw {
  margin-bottom: 10px;
}

.save-id {
  height: 20px;
  margin-bottom: 20px;
}

.login-btn {
  width: 100%;
}
</style>
