<template>
  <div class="tour-register-wrapper">
    <h1 class="page-title">승선권 포함 투어 상품 {{ tourId ? '수정' : '등록' }}</h1>

    <div class="tour-info-common">
      <div class="header-title">기본 정보</div>
      <CruiseInputField label="노출 대상" required>
        <div class="field-wrapper">
          <CruiseCheckbox class="checkbox-width" label="한국어" v-model="tourProductInfo.isOpenedKo" />
          <CruiseCheckbox class="checkbox-width" label="일본어" v-model="tourProductInfo.isOpenedJa" />
          <CruiseCheckbox class="checkbox-width" label="영어" v-model="tourProductInfo.isOpenedEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="노출 상품명" required>
        <div class="field-wrapper">
          <CruiseInput class="input-width" placeholder="한국어 입력" v-model="tourProductInfo.nameKo" />
          <CruiseInput class="input-width" placeholder="일본어 입력" v-model="tourProductInfo.nameJa" />
          <CruiseInput class="input-width" placeholder="영어 입력" v-model="tourProductInfo.nameEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="노출 홍보 문구" required>
        <div class="field-wrapper">
          <CruiseInput class="input-width" placeholder="한국어 입력" v-model="tourProductInfo.promotionTextKo" />
          <CruiseInput class="input-width" placeholder="일본어 입력" v-model="tourProductInfo.promotionTextJa" />
          <CruiseInput class="input-width" placeholder="영어 입력" v-model="tourProductInfo.promotionTextEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="노출 태그">
        <div class="w-100 flex-column">
          <div class="condition-item">
            <CruiseCheckbox class="checkbox-width" label="항로" v-model="isSelectRoute" required disabled />
            <CruiseInput
              class="input-width"
              placeholder="한국어 입력 (예: 부산 > 오사카 > 부산)"
              :disabled="!isSelectRoute"
              v-model="tourProductInfo.tagRouteKo"
            />
            <CruiseInput
              class="input-width"
              placeholder="일본어 입력"
              v-model="tourProductInfo.tagRouteJa"
              :disabled="!isSelectRoute"
            />
            <CruiseInput
              class="input-width"
              placeholder="영어 입력"
              v-model="tourProductInfo.tagRouteEn"
              :disabled="!isSelectRoute"
            />
          </div>

          <div class="condition-item">
            <CruiseCheckbox class="checkbox-width" label="객실" v-model="isSelectGrade" required disabled />
            <CruiseInput
              class="input-width"
              placeholder="한국어 입력 (예: 전체 / 디럭스 스위트, 패밀리 룸)"
              v-model="tourProductInfo.tagGradeKo"
              :disabled="!isSelectGrade"
            />
            <CruiseInput
              class="input-width"
              placeholder="일본어 입력"
              v-model="tourProductInfo.tagGradeJa"
              :disabled="!isSelectGrade"
            />
            <CruiseInput
              class="input-width"
              placeholder="영어 입력"
              v-model="tourProductInfo.tagGradeEn"
              :disabled="!isSelectGrade"
            />
          </div>

          <div class="condition-item">
            <CruiseCheckbox class="checkbox-width" label="기타" v-model="isSelectEtc" />
            <CruiseInput
              class="input-width"
              placeholder="한국어 입력 (예: 매주 월요일 출발)"
              v-model="tourProductInfo.tagEtcKo"
              :disabled="!isSelectEtc"
            />
            <CruiseInput
              class="input-width"
              placeholder="일본어 입력"
              v-model="tourProductInfo.tagEtcJa"
              :disabled="!isSelectEtc"
            />
            <CruiseInput
              class="input-width"
              placeholder="영어 입력"
              v-model="tourProductInfo.tagEtcEn"
              :disabled="!isSelectEtc"
            />
          </div>
        </div>
      </CruiseInputField>

      <CruiseInputField label="등록 상품명(판매자 관리용)" required>
        <div class="field-wrapper">
          <CruiseInput class="input-width" placeholder="등록 상품명 입력" v-model="tourProductInfo.productName" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="인당 추가 금액" required>
        <div class="field-wrapper">
          <CruiseInput
            class="input-width"
            placeholder="숫자 입력"
            v-model="tourProductInfo.packagePrice"
            type="number"
          />
          KRW
        </div>
      </CruiseInputField>
    </div>

    <div class="tour-info-detail">
      <div class="header-title">여정 정보</div>
      <CruiseInputField label="여정 구분" required>
        <div class="field-wrapper">
          <CruiseRadio
            class="checkbox-width"
            name="route"
            v-for="item in tripTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
            v-model="tourProductInfo.isRound"
          />
        </div>
      </CruiseInputField>

      <CruiseInputField label="대상 객실" required>
        <div class="field-wrapper">
          <CruiseSelect
            class="select-width"
            placeholder="객실 추가"
            v-model="currentGrade"
            :options="gradeList"
            @onChange="addGrade"
          >
            <template #option="{ item }">
              {{ item.gradeCode }}
            </template>
          </CruiseSelect>

          <div class="selected-grade-list">
            <div class="grade-item" v-for="(gradeId, idx) in selectedGradeList" :key="gradeId">
              <span> {{ getGradeText(gradeId) }} </span>

              <CloseIcon class="close-icon" @click="deleteGrade(idx)" :size="16" />
            </div>
          </div>
        </div>
      </CruiseInputField>

      <CruiseInputField label="항차 정보" required>
        <div class="field-wrapper">
          <CruiseSelect
            class="select-width-large"
            :placeholder="getLocalText(vesselResetOption, 'name')"
            :options="vesselList"
            v-model="tourProductInfo.vessel.code"
            property="code"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'name') }}
            </template>
          </CruiseSelect>

          <CruiseSelect
            class="select-width"
            :placeholder="getLocalText(routeResetOption, 'name')"
            :options="routeList"
            v-model="tourProductInfo.route.code"
            property="code"
            @onChange="getSaleCodeList"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'name') }}
            </template>
          </CruiseSelect>

          <CruiseSelect
            class="select-width"
            :placeholder="getLocalText(departurePortResetOption, 'name')"
            :options="portList"
            v-model="tourProductInfo.departurePort.code"
            property="code"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'name') }}
            </template>
          </CruiseSelect>

          <CruiseSelect
            class="select-width"
            :placeholder="getLocalText(arrivalPortResetOption, 'name')"
            :options="portList"
            v-model="tourProductInfo.arrivalPort.code"
            property="code"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'name') }}
            </template>
          </CruiseSelect>

          <CruiseDatePicker
            class="input-width"
            v-model:dateRange="voyageDate"
            placeholder="시작 기간 - 종료 기간"
            range
          />
        </div>
        <div v-if="isRound" class="field-wrapper">
          <div class="field-label">기간(일)</div>
          <CruiseInput class="input-width-s" placeholder="숫자 입력" v-model="tourProductInfo.term" type="number" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="투어 상품용 세일 코드">
        <div class="field-wrapper">
          <div class="field-label">성인</div>
          <CruiseSelect
            class="select-width-large"
            placeholder="할인 코드"
            :options="saleCodeList"
            v-model="tourProductInfo.saleCodeAdult"
            property="code"
            @onClick="isSelectedRoute"
          >
            <template #option="{ item }">
              <div class="text-ellipsis" :title="item.description">
                {{ item.description }}
              </div>
            </template>
          </CruiseSelect>

          <div class="field-label">어린이</div>
          <CruiseSelect
            class="select-width-large"
            placeholder="할인 코드"
            :options="saleCodeList"
            v-model="tourProductInfo.saleCodeChild"
            property="code"
            @onClick="isSelectedRoute"
          >
            <template #option="{ item }">
              {{ item.description }}
            </template>
          </CruiseSelect>

          <div class="field-label">유아</div>
          <CruiseSelect
            class="select-width-large"
            placeholder="할인 코드"
            :options="saleCodeList"
            v-model="tourProductInfo.saleCodeInfant"
            property="code"
            @onClick="isSelectedRoute"
          >
            <template #option="{ item }">
              {{ item.description }}
            </template>
          </CruiseSelect>
        </div>
      </CruiseInputField>

      <CruiseInputField label="항차 선택" required>
        <div class="field-wrapper">
          <CruiseButton class="select-width" @click="toggleVoyageListModal">조회</CruiseButton>
        </div>
      </CruiseInputField>

      <CruiseInputField label="상품 상세" required>
        <div class="product-detail">
          <div class="field-wrapper">
            <CruiseTabs v-model:activeTab="activeTab" theme="group">
              <CruiseTab v-for="(tab, i) in tabList" :title="tab.label" :key="tab.code">
                <div class="tab-panel">
                  <div class="cover-img-wrapper">
                    <div class="flex-text header">
                      <div class="field-label large">커버 이미지</div>
                      <span class="desc-text">권장 사이즈: 600px*400px(6:4)</span>
                    </div>

                    <div class="flex-text">
                      <template v-if="getImageUrl('cover', tab.code)">
                        <div class="field-label large">
                          <a :href="getImageUrl('cover', tab.code)" target="_blank">
                            {{ getImageFileName(getImageUrl('cover', tab.code)) }}
                          </a>
                        </div>
                        <CloseIcon class="close-icon" @click="deleteImageUrl('cover', tab.code)" :size="16" />
                      </template>

                      <CruiseButton @click="openFileInput('cover', tab.code)">
                        이미지 {{ getImageUrl('cover', tab.code) ? '변경' : '등록' }}
                      </CruiseButton>
                      <input
                        type="file"
                        :ref="`coverImageFileInput${tab.code}`"
                        @change="uploadImage($event, 'cover', tab.code)"
                        accept=".svg, .jpg, .jpeg, .png"
                        style="display: none"
                      />
                      <div class="desc-text large">JPG, PNG, SVG 파일 확장자만 등록이 가능합니다.</div>
                    </div>
                  </div>

                  <div>
                    <div class="flex-text header">
                      <div class="field-label large">상세 내용 이미지</div>
                      <div class="desc-text">권장 가로 사이즈: 1352px</div>
                    </div>

                    <div class="image-upload-wrapper flex-center">
                      <CruiseButton class="img-upload-btn" @click="openFileInput('route', tab.code)">
                        이미지 {{ getImageUrl('route', tab.code) ? '변경' : '등록' }}
                      </CruiseButton>
                      <input
                        type="file"
                        :ref="`routeImageFileInput${tab.code}`"
                        @change="uploadImage($event, 'route', tab.code)"
                        accept=".svg, .jpg, .jpeg, .png"
                        style="display: none"
                      />
                      <template v-if="getImageUrl('route', tab.code)">
                        <div class="flex-center gap-10">
                          <div class="field-label large">
                            <a :href="getImageUrl('route', tab.code)" target="_blank">
                              {{ getImageFileName(getImageUrl('route', tab.code)) }}
                            </a>
                          </div>
                          <CloseIcon class="close-icon" @click="deleteImageUrl('route', tab.code)" :size="16" />
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </CruiseTab>
            </CruiseTabs>
          </div>
        </div>
      </CruiseInputField>

      <CruiseInputField label="판매 상태 설정" required>
        <div class="field-wrapper">
          <CruiseRadio
            class="checkbox-width"
            name="route"
            v-for="item in statusList"
            :key="item.statusCode"
            :label="getLocalText(item, 'status')"
            :value="item.statusCode"
            v-model="tourProductInfo.statusCode"
          />
        </div>
      </CruiseInputField>
    </div>

    <div class="btn-wrapper">
      <CruiseButton class="select-width" @click="saveTourProduct">{{ tourId ? '수정' : '저장' }}</CruiseButton>
      <CruiseButton class="select-width" theme="s" @click="cancel">취소</CruiseButton>
    </div>
  </div>
  <VoyageListModal
    v-if="isShowVoyageListModal"
    v-model:showModal="isShowVoyageListModal"
    :tourProductInfo="tourProductInfo"
    :voyageDate="voyageDate"
    :isRound="tourProductInfo.isRound"
    :backUpSelectedList="selectedVoyageListBackup"
    @saveVoyageList="saveVoyageList"
  />
</template>
<script>
import dayjs from 'dayjs';
import { YYYYMMDD } from '@/const/const';
import { regExp } from '@/utils/validator';
import { uploadFileToAzure } from '@/utils/azure';
import { mapMutations } from 'vuex';
import { getLocalText, getLocalKey, formatDate } from '@/utils/convert';

import TourService from '@/services/tour';
import VesselService from '@/services/vessel';
import RouteService from '@/services/route';
import PortService from '@/services/port';

import CloseIcon from 'vue-material-design-icons/Close';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseInputField from '@/components/common/CruiseInputField.vue';
import CruiseRadio from '@/components/common/CruiseRadio.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import CruiseDatePicker from '@/components/common/CruiseDatePicker.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseTabs from '@/components/tab/CruiseTabs.vue';
import CruiseTab from '@/components/tab/CruiseTab.vue';
import VoyageListModal from '@/components/tour/VoyageListModal';

export default {
  name: 'TourRegister',
  components: {
    CloseIcon,
    CruiseCheckbox,
    CruiseInput,
    CruiseInputField,
    CruiseRadio,
    CruiseSelect,
    CruiseDatePicker,
    CruiseButton,
    CruiseTabs,
    CruiseTab,
    VoyageListModal,
  },
  data() {
    return {
      regExp,
      activeTab: 0,
      isShowVoyageListModal: false,

      tabList: [
        { code: 'Ko', label: '한국어' },
        { code: 'Ja', label: '일본어' },
        { code: 'En', label: '영어' },
      ],
      tripTypeList: [
        { code: true, name: '왕복' },
        { code: false, name: '편도' },
      ],
      statusList: [
        { statusCode: 'IPG', statusKo: '판매 중', statusJa: '販売中', statusEn: 'On Sale' },
        { statusCode: 'PND', statusKo: '판매 대기', statusJa: '販売待ち', statusEn: 'Pending' },
        { statusCode: 'CMP', statusKo: '판매 종료', statusJa: '販売終了', statusEn: 'Closed' },
      ],

      vesselResetOption: { code: '', nameKo: '선박', nameJa: '船', nameEn: 'Vessel' },
      routeResetOption: { code: '', nameKo: '항로', nameJa: '航路', nameEn: 'Route' },
      departurePortResetOption: { code: '', nameKo: '출항지', nameJa: '出港地', nameEn: 'Port of Departure' },
      arrivalPortResetOption: { code: '', nameKo: '도착지', nameJa: '到着地', nameEn: 'Port of Arrival' },

      vessel: '',
      route: '',
      departurePort: '',
      arrivalPort: '',
      voyageDate: [],
      selectedVoyageListBackup: [], // 선택된 항차리스트 임시 저장

      // async data
      gradeList: [],
      vesselList: [],
      routeList: [],
      portList: [],
      saleCodeList: [],

      isSelectRoute: true,
      isSelectGrade: true,
      isSelectEtc: false,

      currentGrade: null,
      selectedGradeList: [],

      // input data
      tourProductInfo: {
        /** 기본정보 */
        // 노출대상
        isOpenedKo: true,
        isOpenedJa: true,
        isOpenedEn: true,
        // 노출상품명
        nameKo: '',
        nameJa: '',
        nameEn: '',
        // 노출홍보문구
        promotionTextKo: '',
        promotionTextJa: '',
        promotionTextEn: '',
        // 노출태그: 항로
        tagRouteKo: '',
        tagRouteJa: '',
        tagRouteEn: '',
        // 노출태그: 객실
        tagGradeKo: '',
        tagGradeJa: '',
        tagGradeEn: '',
        // 노출태그: 기타
        tagEtcKo: '',
        tagEtcJa: '',
        tagEtcEn: '',
        // 등록상품명
        productName: '',
        // 인당 추가 금액
        packagePrice: '',

        /** 여정정보 */
        isRound: true,
        vessel: { code: '' },
        route: { code: '' },
        departurePort: { code: '' },
        arrivalPort: { code: '' },
        startDate: '',
        endDate: '',
        term: null,
        saleCodeAdult: '',
        saleCodeChild: '',
        saleCodeInfant: '',
        statusCode: 'IPG',
        statusKo: '',
        statusJa: '',
        statusEn: '',
        coverImageUrlKo: '',
        routeImageUrlKo: '',
        coverImageUrlJa: '',
        routeImageUrlJa: '',
        coverImageUrlEn: '',
        routeImageUrlEn: '',
      },
    };
  },
  computed: {
    tourId() {
      return this.$route.query.id;
    },
    isRound() {
      return this.tourProductInfo.isRound;
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalText,
    formatDate,

    async init() {
      try {
        this.setIsLoading(true);

        await this.getGradeList();
        await this.initializeTourProduct();

        if (this.tourId) {
          this.getSaleCodeList();
        }

        const responses = await Promise.all([
          VesselService.getVesselList(),
          RouteService.getRouteList(),
          PortService.getPortList(),
        ]);

        [this.vesselList, this.routeList, this.portList] = responses.map(response => response.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async getGradeList() {
      try {
        const gradeNameByLocalText = getLocalKey('gradeName');

        const { data } = await TourService.getTourGradeList();
        this.gradeList = data.map(item => ({
          id: item.id,
          gradeName: item[gradeNameByLocalText],
          gradeCode: item.gradeCode,
        }));
      } catch (error) {
        alert(error);
      }
    },
    getGradeText(gradeId) {
      const getSelectedGrade = this.gradeList.find(grade => grade.id === gradeId);
      return getSelectedGrade.gradeCode;
    },
    addGrade(grade, _, e) {
      e.target.selectedIndex = 0;
      const selectedGradeId = grade.id;
      if (!this.selectedGradeList.includes(selectedGradeId)) this.selectedGradeList.push(selectedGradeId);
    },
    deleteGrade(idx) {
      this.selectedGradeList.splice(idx, 1);
    },
    isSelectedRoute() {
      if (!this.tourProductInfo.route.code) {
        alert('항로를 선택해주세요.');
        return;
      }
    },
    async getSaleCodeList() {
      try {
        this.setIsLoading(true);

        const params = {
          routeCode: this.tourProductInfo.route.code,
        };
        const { data } = await TourService.getSaleCodeList(params);
        this.saleCodeList = data;
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },

    // init tour product for set
    async initializeTourProduct() {
      if (!this.tourId) {
        return;
      }

      try {
        this.setIsLoading(true);
        const { data } = await TourService.getTourProductDetail(this.tourId);
        this.tourProductInfo = {
          ...data.packageTour,
        };

        this.selectedGradeList = data.packageTour.appliedGradeIds;
        this.voyageDate = [dayjs(data.packageTour.startDate), dayjs(data.packageTour.endDate)];
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    getImageUrl(type, languageCode) {
      return this.tourProductInfo[`${type}ImageUrl${languageCode}`];
    },
    getImageFileName(fileUrl) {
      const lastSegment = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      const cleanedFilename = lastSegment.replace(/^\d{21}/, '');

      return cleanedFilename;
    },
    openFileInput(type, languageCode) {
      const inputFile = `${type}ImageFileInput${languageCode}`;
      this.$refs[inputFile][0].click();
    },
    async uploadImage(event, type, languageCode) {
      const file = event.target.files[0];
      const fileUrl = await uploadFileToAzure(file, 'tour-product');

      const imagePropertyName = `${type}ImageUrl${languageCode}`;
      this.tourProductInfo[imagePropertyName] = fileUrl;
    },
    deleteImageUrl(type, languageCode) {
      // NOTE: 프론트에서만 처리된 상태로 수정/저장 시 서버에는 이미지 삭제가 반영되지 않음
      this.tourProductInfo[`${type}ImageUrl${languageCode}`] = '';
    },
    toggleVoyageListModal() {
      if (!this.tourProductInfo.vessel.code) {
        alert('선박을 선택해주세요.');
        return;
      }

      if (!this.tourProductInfo.route.code) {
        alert('항로를 선택해주세요.');
        return;
      }

      if (!this.tourProductInfo.departurePort.code) {
        alert('출항지를 선택해주세요.');
        return;
      }

      if (!this.tourProductInfo.arrivalPort.code) {
        alert('도착지를 선택해주세요.');
        return;
      }

      if (this.voyageDate.length === 0) {
        alert('시작/종료 기간을 선택해주세요.');
        return;
      }

      if (this.isRound && !this.tourProductInfo.term) {
        alert('기간을 입력해주세요.');
        return;
      }

      this.isShowVoyageListModal = !this.isShowVoyageListModal;
    },
    getStatusByCode(code) {
      return this.statusList.find(status => status.statusCode === code);
    },
    saveVoyageList(selectedVoyageList) {
      const convertedCheckedVoyageList = selectedVoyageList.map(voyage => ({
        leaveVoyageNumber: voyage.leaveVoyageNumber,
        returnVoyageNumber: voyage.returnVoyageNumber,
        leaveDate: voyage.leaveDate,
        returnDate: voyage.returnDate,
      }));

      // 이전에 선택된 체크리스트 조회를 위해 백업용으로 저장
      this.selectedVoyageListBackup = selectedVoyageList;

      // 선택된 항차리스트 저장
      this.tourProductInfo.packageTourSchedule = convertedCheckedVoyageList;
    },
    canSave() {
      if (!this.tourProductInfo.isOpenedKo && !this.tourProductInfo.isOpenedJa && !this.tourProductInfo.isOpenedEn) {
        alert('노출 대상 언어를 1개 이상 선택해주세요.');
        return false;
      }

      if (this.tourProductInfo.isOpenedKo) {
        if (
          !this.tourProductInfo.nameKo ||
          !this.tourProductInfo.promotionTextKo ||
          (this.isSelectRoute && !this.tourProductInfo.tagRouteKo) ||
          (this.isSelectGrade && !this.tourProductInfo.tagGradeKo) ||
          (this.isSelectEtc && !this.tourProductInfo.tagEtcKo)
        ) {
          alert('한국어 항목을 모두 입력해주세요.');
          return false;
        }
      }

      if (this.tourProductInfo.isOpenedJa) {
        if (
          !this.tourProductInfo.nameJa ||
          !this.tourProductInfo.promotionTextJa ||
          (this.isSelectRoute && !this.tourProductInfo.tagRouteJa) ||
          (this.isSelectGrade && !this.tourProductInfo.tagGradeJa) ||
          (this.isSelectEtc && !this.tourProductInfo.tagEtcJa) ||
          (this.isSelectEtc && !this.tourProductInfo.tagEtcEn)
        ) {
          alert('일본어 항목을 모두 입력해주세요.');
          return false;
        }
      }

      if (this.tourProductInfo.isOpenedEn) {
        if (
          !this.tourProductInfo.nameEn ||
          !this.tourProductInfo.promotionTextEn ||
          (this.isSelectRoute && !this.tourProductInfo.tagRouteEn) ||
          (this.isSelectGrade && !this.tourProductInfo.tagGradeEn)
        ) {
          alert('영어 항목을 모두 입력해주세요.');
          return false;
        }
      }

      if (!this.tourProductInfo.productName) {
        alert('등록 상품명을 입력해주세요.');
        return false;
      }

      if (!this.tourProductInfo.packagePrice) {
        alert('인당 추가 금액을 입력해주세요.');
        return false;
      }

      if (this.selectedGradeList.length === 0) {
        alert('대상 객실을 1게 이상 선택해주세요.');
        return false;
      }

      if (
        !this.tourProductInfo.vessel.code ||
        !this.tourProductInfo.route.code ||
        !this.tourProductInfo.departurePort.code ||
        !this.tourProductInfo.arrivalPort.code
      ) {
        alert('항차 정보를 모두 선택해주세요.');
        return false;
      }

      if (this.voyageDate.length === 0) {
        alert('항차 정보의 시작/종료 기간을 선택해주세요.');
        return false;
      }

      if (this.isRound && !this.tourProductInfo.term) {
        alert('기간을 입력해주세요.');
        return false;
      }

      if (this.tourProductInfo?.packageTourSchedule?.length === 0) {
        alert('항차를 선택해주세요.');
        return false;
      }

      if (this.tourProductInfo.isOpenedKo) {
        if (!this.tourProductInfo.coverImageUrlKo || !this.tourProductInfo.routeImageUrlKo) {
          alert('한국어 상품 상세 이미지를 모두 등록해주세요.');
          return false;
        }
      }

      if (this.tourProductInfo.isOpenedJa) {
        if (!this.tourProductInfo.coverImageUrlJa || !this.tourProductInfo.routeImageUrlJa) {
          alert('일본어 상품 상세 이미지를 모두 등록해주세요.');
          return false;
        }
      }

      if (this.tourProductInfo.isOpenedEn) {
        if (!this.tourProductInfo.coverImageUrlEn || !this.tourProductInfo.routeImageUrlEn) {
          alert('영어 상품 상세 이미지를 모두 등록해주세요.');
          return false;
        }
      }
      return true;
    },
    // add tour product
    async saveTourProduct() {
      if (!this.canSave()) {
        return;
      }

      try {
        this.setIsLoading(true);

        const status = this.getStatusByCode(this.tourProductInfo.statusCode);

        const tourProductInfo = {
          ...this.tourProductInfo,
          tagRouteKo: this.isSelectRoute ? this.tourProductInfo.tagRouteKo : '',
          tagRouteJa: this.isSelectRoute ? this.tourProductInfo.tagRouteJa : '',
          tagRouteEn: this.isSelectRoute ? this.tourProductInfo.tagRouteEn : '',
          tagGradeKo: this.isSelectGrade ? this.tourProductInfo.tagGradeKo : '',
          tagGradeJa: this.isSelectGrade ? this.tourProductInfo.tagGradeJa : '',
          tagGradeEn: this.isSelectGrade ? this.tourProductInfo.tagGradeEn : '',
          tagEtcKo: this.isSelectEtc ? this.tourProductInfo.tagEtcKo : '',
          tagEtcJa: this.isSelectEtc ? this.tourProductInfo.tagEtcJa : '',
          tagEtcEn: this.isSelectEtc ? this.tourProductInfo.tagEtcEn : '',
          packagePrice: Number(this.tourProductInfo.packagePrice) || 0,
          startDate: this.formatDate(this.voyageDate[0], YYYYMMDD),
          endDate: this.formatDate(this.voyageDate[1], YYYYMMDD),
          term: this.isRound ? Number(this.tourProductInfo.term) : null,
          appliedGradeIds: this.selectedGradeList || [],
          statusKo: status ? status.statusKo : '',
          statusJa: status ? status.statusJa : '',
          statusEn: status ? status.statusEn : '',
          currency: {
            id: 1,
          },
        };

        if (!this.tourId) {
          await TourService.addTourProduct(tourProductInfo);
        } else {
          await TourService.setTourProduct(this.tourId, tourProductInfo);
        }

        alert(`투어상품 ${this.tourId ? '수정' : '저장'}이 완료되었습니다.`);
        this.$router.push('/tour');
      } catch (error) {
        alert(`투어상품 ${this.tourId ? '수정' : '저장'}중 오류가 발생했습니다.`);
      } finally {
        this.setIsLoading(false);
      }
    },
    cancel() {
      const result = confirm('확인을 누르시면 작성한 내용은 저장되지 않습니다.\n취소하시겠습니까?');
      if (result) {
        this.$router.push('/tour');
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.tour-register-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 24px;
}

.tour-info-common,
.tour-info-detail {
  display: flex;
  flex-direction: column;
  border: 0.5px solid #f0f0f0;
}

.product-detail {
  padding: 10px 0;
  width: 100%;
}

.tab-panel {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
}

.flex-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.image-upload-wrapper {
  width: auto;
  height: 220px;
  border: 0.5px solid #969696;
  flex-direction: column;
  gap: 10px;
}

.header {
  margin-bottom: 10px;
}

.field-label {
  font-size: 12px;
  font-weight: 400;
}

.field-label.large {
  font-size: 14px;
}

.desc-text {
  color: #969696;
  font-size: 12px;
  font-weight: 400;
}

.desc-text.large {
  font-size: 14px;
}

.input-width {
  width: 250px !important;
}

.input-width-s {
  width: 100px !important;
}

.select-width {
  flex-shrink: 0;
  width: 120px;
}

.select-width-large {
  flex-shrink: 0;
  width: 200px;
}

.checkbox-width {
  min-width: 90px;
}

.condition-item {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  border-bottom: 0.5px solid #f0f0f0;
  padding: 0 20px;
  border-left: none;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}

.condition-item:last-child {
  border-bottom: none;
}

.field-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
}

.selected-grade-list {
  display: flex;
  gap: 5px;
  width: 100%;
  height: 40px;
}

.grade-item {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
}

.close-icon {
  cursor: pointer;
  border-radius: 50%;
  color: #fff;
  background: #d9d9d9;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
